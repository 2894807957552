import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "Settings--Content" }
const _hoisted_2 = { class: "Settings--Grid" }
const _hoisted_3 = { class: "FloatingSelect" }
const _hoisted_4 = ["value"]
const _hoisted_5 = { for: "" }
const _hoisted_6 = { class: "FloatingSelect" }
const _hoisted_7 = ["value"]
const _hoisted_8 = { for: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SuiteInput = _resolveComponent("SuiteInput")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.$t('suite_settings_billing_title')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_SuiteInput, {
        class: "Floating",
        ref: "billingCompanyName",
        modelValue: _ctx.billing.billingCompanyName,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.billing.billingCompanyName) = $event)),
        label: _ctx.$t('suite_form_billing_name'),
        required: ""
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_SuiteInput, {
        class: "Floating",
        ref: "identificationNum",
        modelValue: _ctx.billing.identificationNum,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.billing.identificationNum) = $event)),
        label: _ctx.$t('suite_form_billing_identinum'),
        required: "",
        validation: /\b\S{9}\b/g
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_SuiteInput, {
        class: "Floating",
        ref: "billingAddress",
        modelValue: _ctx.billing.billingAddress,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.billing.billingAddress) = $event)),
        label: _ctx.$t('suite_form_billing_address'),
        required: ""
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_SuiteInput, {
        class: "Floating",
        ref: "billingPostalCode",
        modelValue: _ctx.billing.billingPostalCode,
        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.billing.billingPostalCode) = $event)),
        label: _ctx.$t('suite_form_billing_postal_code'),
        required: "",
        validation: /\b\d{5}\b/g
      }, null, 8, ["modelValue", "label"]),
      _createVNode(_component_SuiteInput, {
        class: "Floating",
        ref: "billingCity",
        modelValue: _ctx.billing.billingCity,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.billing.billingCity) = $event)),
        label: _ctx.$t('suite_form_billing_locality'),
        required: ""
      }, null, 8, ["modelValue", "label"]),
      _createElementVNode("div", _hoisted_3, [
        _withDirectives(_createElementVNode("select", {
          class: "",
          name: "",
          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.billing.billingProvince.elementId) = $event))
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.provinces, (province) => {
            return (_openBlock(), _createElementBlock("option", {
              value: province.id
            }, _toDisplayString(_ctx.$t(province.value)), 9, _hoisted_4))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.billing.billingProvince.elementId]
        ]),
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('suite_form_billing_province')), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("select", {
          class: "",
          name: "",
          "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.contactData.companyCountry.elementId) = $event)),
          disabled: ""
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.countries, (country) => {
            return (_openBlock(), _createElementBlock("option", {
              value: country.id
            }, _toDisplayString(_ctx.$t(country.value)), 9, _hoisted_7))
          }), 256))
        ], 512), [
          [_vModelSelect, _ctx.contactData.companyCountry.elementId]
        ]),
        _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('suite_form_billing_country')), 1)
      ])
    ]),
    _createVNode(_component_Button, {
      class: "Gradient",
      onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.submitForm(_ctx.billing))),
      disabled: _ctx.disableBillingSubmit()
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('suite_action_save')), 1)
      ]),
      _: 1
    }, 8, ["disabled"])
  ]))
}