import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "Settings" }
const _hoisted_2 = { class: "Settings--Box" }
const _hoisted_3 = { class: "Settings--Tabs" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettingsPassword = _resolveComponent("SettingsPassword")!
  const _component_SettingsContact = _resolveComponent("SettingsContact")!
  const _component_SettingsBilling = _resolveComponent("SettingsBilling")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabs, (tab, index) => {
          return (_openBlock(), _createElementBlock("p", {
            class: _normalizeClass({'selected':_ctx.tabNum == index}),
            onClick: ($event: any) => (_ctx.tabNum = index)
          }, _toDisplayString(tab), 11, _hoisted_4))
        }), 256))
      ]),
      _withDirectives(_createVNode(_component_SettingsPassword, null, null, 512), [
        [_vShow, _ctx.tabNum == 0]
      ]),
      _withDirectives(_createVNode(_component_SettingsContact, null, null, 512), [
        [_vShow, _ctx.tabNum == 1]
      ]),
      _withDirectives(_createVNode(_component_SettingsBilling, null, null, 512), [
        [_vShow, _ctx.tabNum == 2]
      ])
    ])
  ]))
}