
import Swal from 'sweetalert2'
import { defineComponent, ref } from 'vue';
import { mapGetters } from 'vuex'
import { Button } from '@/components'
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import store from '@/store'
interface InputInterface{
  error: Boolean,
  errorMessage: String,
  isRequiredError(): any,
  hasErrors():any
}

export default defineComponent({
  name : 'Settings',
  components:{
    SuiteInput,
    Button
  },
  props:{
    isAfterLogin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'clientData',
      'languagesAvailable',
      'clientCountry'
    ])
  },
  data(){
    return{
      passwordForm:{
        oldPassword:'',
        newPassword:'',
        newPasswordRepeat:''
      }
    }
  },
  methods:{
    async updateCountry(event: any){
      await store.dispatch('updateClientLanguage',{language:event.target.value.toLowerCase()})
      store.dispatch('getCreditsPlan')
    },
    disablePasswordSubmit(){
      if(this.$refs.oldPassword && (this.$refs.oldPassword as InputInterface).isRequiredError()) return true
      else if(this.$refs.newPassword && (this.$refs.newPassword as InputInterface).isRequiredError()) return true
      else if(this.$refs.newPasswordRepeat && (this.$refs.newPasswordRepeat as InputInterface).isRequiredError()) return true
      else if(this.passwordForm.newPassword.length == 0) return true
      else if(this.passwordForm.newPassword != this.passwordForm.newPasswordRepeat) return true
      return false
    },
    async saveNewPassword(){
      let vm = this
      const data = {
        oldPassword:this.passwordForm.oldPassword,
        newPassword:this.passwordForm.newPassword,
      }
      await store.dispatch('newPassword', data)
      .then((data) => {
        Swal.fire(
          vm.$t('suite_swal_password_success'),
          '',
          'success'
        )
        this.passwordForm = {
          oldPassword:'',
          newPassword:'',
          newPasswordRepeat:''
        }
        this.$emit('saved-data')
      })
      .catch((response) => {
        const passwordInput = (this.$refs.oldPassword as InputInterface)
        passwordInput.error = true
        passwordInput.errorMessage = this.$t('suite_settings_password_error')
      })
    }
  }
})
