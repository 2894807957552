
import Swal from 'sweetalert2'
import { defineComponent } from 'vue';
import SettingsContact from '@/views/SettingsContact'
import SettingsBilling from '@/views/SettingsBilling'
import SettingsPassword from '@/views/SettingsPassword'
import store from '@/store'
interface InputInterface{
  error: Boolean,
  errorMessage: String,
  hasErrors():any
}

export default defineComponent({
  name : 'Settings',
  components:{
    SettingsContact,
    SettingsBilling,
    SettingsPassword
  },
  data(){
    return{
      tabNum:0,
      tabs:[this.$t('suite_settings_tab_account'), this.$t('suite_settings_tab_contact'), this.$t('suite_settings_tab_billing')]
    }
  }
})
