
import Swal from 'sweetalert2'
import { defineComponent, ref } from 'vue';
import { mapGetters } from 'vuex'
import { Button } from '@/components'
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import store from '@/store'

interface InputInterface{
  error: Boolean,
  errorMessage: String,
  isRequiredError():any,
  hasErrors():any
}

export default defineComponent({
  name : 'Settings',
  components:{
    SuiteInput,
    Button
  },
  props:{
    isAfterLogin:{
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'clientData',
      'company_sector',
      'countries',
      'provinces'
    ])
  },
  watch:{
    clientData(newValue, lastValue){
      this.setDefaultForms()
    }
  },
  data(){
    return{
      contactData:{
        companyName: null,
        sector: {elementId:0},
        address: null,
        postalCode: null,
        locality: null,
        province: {elementId:0},
        companyCountry: {elementId:0},
        contactPhone: null,
        website: null,
        contactName: null,
        contactSurname1: null,
        contactSurname2: null,
        picture: null,
        companyLogoUrl: null
      }
    }
  },
  methods:{
    disableContactSubmit(){
      if(this.$refs.companyName && (this.$refs.companyName as InputInterface).isRequiredError()) return true
      else if(this.$refs.sector && (this.$refs.sector as InputInterface).isRequiredError()) return true
      else if(this.$refs.address && (this.$refs.address as InputInterface).isRequiredError()) return true
      else if(this.$refs.postalCode && (this.$refs.postalCode as InputInterface).isRequiredError()) return true
      else if(this.$refs.locality && (this.$refs.locality as InputInterface).isRequiredError()) return true
      else if(this.$refs.contactPhone && (this.$refs.contactPhone as InputInterface).isRequiredError()) return true
      else if(this.$refs.website && (this.$refs.website as InputInterface).isRequiredError()) return true
      else if(this.$refs.contactName && (this.$refs.contactName as InputInterface).isRequiredError()) return true
      else if(this.$refs.contactSurname1 && (this.$refs.contactSurname1 as InputInterface).isRequiredError()) return true
      else if(this.$refs.contactSurname2 && (this.$refs.contactSurname2 as InputInterface).isRequiredError()) return true
      return false
    },
    setDefaultForms(){
      this.contactData = {
        companyName: this.clientData.companyName,
        sector: {elementId:this.clientData.sector?.elementId},
        address: this.clientData.address,
        postalCode: this.clientData.postalCode,
        locality: this.clientData.locality,
        province: {elementId:this.clientData.province?.elementId},
        companyCountry: {elementId:this.clientData.companyCountry != null ? this.clientData.companyCountry.elementId : 0},
        contactPhone: this.clientData.contactPhone?.number,
        website: this.clientData.website,
        contactName: this.clientData.contactName,
        contactSurname1: this.clientData.contactSurname1,
        contactSurname2: this.clientData.contactSurname2,
        picture: this.clientData.picture,
        companyLogoUrl: this.clientData.companyLogoUrl
      }
    },
    uploadPicture(){
      const files = (document.getElementById('file-picture') as HTMLInputElement).files;
      if (files == null || files.length == 0) return
      store.dispatch('uploadFile', files != undefined ? files[0] : null)
      .then((data) => {
        this.contactData.picture = data.path
      })
    },
    uploadcompanyLogoUrl(){
      const files = (document.getElementById('file-companyLogoUrl') as HTMLInputElement).files;
      if (files == null || files.length == 0) return
      store.dispatch('uploadFile', files != undefined ? files[0] : null)
      .then((data) => {
        this.contactData.companyLogoUrl = data.path
      })
    },
    isFormAvailable(){
      let error = true
      if(this.$refs.companyName && (this.$refs.companyName as InputInterface).hasErrors()) error = false
      if(this.$refs.sector && (this.$refs.sector as InputInterface).hasErrors()) error = false
      if(this.$refs.address && (this.$refs.address as InputInterface).hasErrors()) error = false
      if(this.$refs.postalCode && (this.$refs.postalCode as InputInterface).hasErrors()) error = false
      if(this.$refs.locality && (this.$refs.locality as InputInterface).hasErrors()) error = false
      if(this.$refs.contactPhone && (this.$refs.contactPhone as InputInterface).hasErrors()) error = false
      if(this.$refs.website && (this.$refs.website as InputInterface).hasErrors()) error = false
      if(this.$refs.contactName && (this.$refs.contactName as InputInterface).hasErrors()) error = false
      if(this.$refs.contactSurname1 && (this.$refs.contactSurname1 as InputInterface).hasErrors()) error = false
      if(this.$refs.contactSurname2 && (this.$refs.contactSurname2 as InputInterface).hasErrors()) error = false
      return error
    },
    async submitForm(data: any){
      // if(!this.isFormAvailable()) return
      let vm = this
      await store.dispatch('putClientData', data)
      .then((data) => {
        Swal.fire(
          vm.$t('suite_swal_info_saved'),
          '',
          'success'
        )
        this.$emit('saved-data')
      })
      .catch((response) => {
        Swal.fire(
          vm.$t('suite_swal_error'),
          '',
          'error'
        )
      })
    }
  },
  async mounted(){
    this.setDefaultForms()
  }
})
