
import Swal from 'sweetalert2'
import { defineComponent, ref } from 'vue';
import { mapGetters } from 'vuex'
import { Button } from '@/components'
import SuiteInput from '~/components/SuiteInput/SuiteInput.vue'
import store from '@/store'
interface InputInterface{
  error: Boolean,
  errorMessage: String,
  isRequiredError():any,
  hasErrors():any
}

export default defineComponent({
  name : 'SettingsBilling',
  components:{
    SuiteInput,
    Button
  },
  computed: {
    ...mapGetters([
      'clientData',
      'company_sector',
      'countries',
      'provinces'
    ])
  },
  watch:{
    clientData(newValue, lastValue){
      this.setDefaultForms()
    }
  },
  data(){
    return{
      contactData:{
        companyCountry: {elementId:0},
      },
      billing:{
        billingAddress: null,
        billingCity: null,
        billingCompanyName: null,
        billingPostalCode: null,
        billingProvince: {elementId:0}, // elementId
        identificationNum: null
      }
    }
  },
  methods:{
    disableBillingSubmit(){
      if(this.$refs.billingAddress && (this.$refs.billingAddress as InputInterface).isRequiredError()) return true
      else if(this.$refs.billingCity && (this.$refs.billingCity as InputInterface).isRequiredError()) return true
      else if(this.$refs.billingCompanyName && (this.$refs.billingCompanyName as InputInterface).isRequiredError()) return true
      else if(this.$refs.billingPostalCode && (this.$refs.billingPostalCode as InputInterface).isRequiredError()) return true
      else if(this.$refs.identificationNum && (this.$refs.identificationNum as InputInterface).isRequiredError()) return true
      return false
    },
    setDefaultForms(){
      this.contactData = {
        companyCountry: {elementId:this.clientData.companyCountry != null ? this.clientData.companyCountry.elementId : 0},
      }
      if(this.clientData.billingAddress){
        this.billing = {
          billingAddress: this.clientData.billingAddress.billingAddress,
          billingCity: this.clientData.billingAddress.billingCity,
          billingCompanyName: this.clientData.billingAddress.billingCompanyName,
          billingPostalCode: this.clientData.billingAddress.billingPostalCode,
          billingProvince: {elementId:this.clientData.billingAddress.billingProvince?.elementId}, // elementId
          identificationNum: this.clientData.billingAddress.identificationNum
        }
      }
    },
    isFormAvailable(){
      if(this.$refs.billingAddress && (this.$refs.billingAddress as InputInterface).hasErrors()) return false
      if(this.$refs.billingCity && (this.$refs.billingCity as InputInterface).hasErrors()) return false
      if(this.$refs.billingCompanyName && (this.$refs.billingCompanyName as InputInterface).hasErrors()) return false
      if(this.$refs.billingPostalCode && (this.$refs.billingPostalCode as InputInterface).hasErrors()) return false
      if(this.$refs.identificationNum && (this.$refs.identificationNum as InputInterface).hasErrors()) return false
      return true
    },
    async submitForm(data: any){
      let vm = this
      if(!this.isFormAvailable()) return
      await store.dispatch('putClientData', data)
      .then((data) => {
        Swal.fire(
          vm.$t('suite_swal_info_saved'),
          '',
          'success'
        )
        this.$emit('saved-data')
      })
      .catch((response) => {
        Swal.fire(
          vm.$t('suite_swal_error'),
          '',
          'error'
        )
      })
    },
  },
  async mounted(){
    this.setDefaultForms()
  }
})
